<template>
  <v-text-field
      ref="inputRef"
      type="text"
      :label="label"
      :disabled="disabled"
      required
      outlined
      dense
      color="primary"
      class="input-field"
      :class="{'input-field-large': large}"
      :rules="rules"></v-text-field>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    options: Object,
    rules: Array,
    label: String,
    value: String,
    disabled: false,
    large: false
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);
    return { inputRef };
  },
};
</script>
<style lang="scss">
.input-field-large {
  input {
    font-size: 1.5em !important;
    max-height: 39px !important;
  }
}
</style>